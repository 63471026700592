import { Injectable } from '@angular/core';
import { WebAppHealthAndInfoService } from '@root/api/idlt';

import { map, of, catchError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApiVersionService {

  constructor(
    private readonly infoService: WebAppHealthAndInfoService
  ) {
    window['infoService'] = this;
  }

  getApiInfo() {
    console.log('-> getting version...');
    return this.infoService
      .getInfoInfoGet()
      .pipe(
        map(res => res),
        catchError(() => of(null))
      ).subscribe(version => {
        if (!version) {
          console.log('Error getting version');
          return;
        }
        console.log('-> version: ', version)
      })
  }
}
