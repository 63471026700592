  <mat-toolbar class="topnav">
    <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-icon" aria-label="Menu">
      <mat-icon>apps</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <!-- iDLT Connect Menu -->
      @if (canActivateItem(appPaths.iDLTConnect_menuItem) | async) {
        <button mat-menu-item [matMenuTriggerFor]="idlt">
          <span>iDLT Connect</span>
        </button>
      }
      <!-- Reporting Menu -->
      @if (canActivateItem(appPaths.admin_menuItem) | async ) {
      <button mat-menu-item [matMenuTriggerFor]="admin">
        <span>Admin</span>
      </button>
      }
      @if (canActivateItem(appPaths.canoe_data_extraction) | async) {
      <button mat-menu-item routerLink="/canoe-data-extraction">
        <span>Canoe Data Extraction</span>
      </button>
      }
      @if (canActivateItem(appPaths.ai_report) | async) {
      <button mat-menu-item routerLink="/ai-report">
        <span>Document Data Extraction</span>
      </button>
      }
      @if (canActivateItem(appPaths.report) | async) {
      <button mat-menu-item routerLink="/report">
        <span>Capital Event</span>
      </button>
      }
    </mat-menu>
    <!-- iDLT Connect sub Menu -->
    <mat-menu #idlt="matMenu">
      @if (canActivateItem(appPaths.fund_management) | async) {
        <button mat-menu-item routerLink="/fund-management">
          <span>Fund Management</span>
        </button>
      }
      @if (canActivateItem(appPaths.subscription_management) | async) {
        <button mat-menu-item routerLink="/subscription-management">
          <span>Subscription Management</span>
        </button>
      }
      @if (canActivateItem(appPaths.capital_event_management) | async) {
        <button mat-menu-item routerLink="/capital-event-management">
          <span>Capital Event Management</span>
        </button>
      }
    </mat-menu>
    <!-- Reporting sub Menu -->
    <mat-menu #admin="matMenu">
      @if (canActivateItem(appPaths.admin_document_type) | async) {
      <button mat-menu-item routerLink="/admin/document-type">
        <span>Document Data Extraction</span>
      </button>
      }
    </mat-menu>
  <div class="class">
    <span class="breadcrumbs">{{breadCrumbs.join(' - ')}}</span>
  </div>
  <div class="topnav__content">
    @if (showSponsorSelect) {
      <span style="width: 50%;">
        <mat-form-field>
          <mat-select panelClass="sponsor-select" [(ngModel)]="sponsor" (selectionChange)="onSponsorChange($event)" #sponsorSelect >
            <mat-option *ngFor="let sponsor of sponsors" [value]="sponsor" selec >
              {{sponsor.sponsorName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </span>
    }
  </div>
  <span class="fill-remaining-space"></span>
  <span>
    <img alt="GenII Fund Services" src="/assets/Gen-II-Logo.png">
  </span>
</mat-toolbar>