import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { withFetch, withInterceptorsFromDi, provideHttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideRouter, TitleStrategy } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { environment } from '@root/environments/environment';

import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { MatNativeDateModule } from "@angular/material/core";

import { 
  ApiModule as BoxAPIModule, 
  Configuration as BoxAPIConfiguration, 
  ConfigurationParameters as BoxAPIConfigurationParameters
} from '@root/api/box';
import { 
  ApiModule as CanoeAPIModule, 
  Configuration as CanoeAPIConfiguration, 
  ConfigurationParameters as CanoeAPIConfigurationParameters
} from '@root/api/canoe';
import { 
  ApiModule as EntityAPIModule, 
  Configuration as EntityAPIConfiguration, 
  ConfigurationParameters as EntityAPIConfigurationParameters
} from '@root/api/entity';
import { 
  ApiModule as IDLTAPIModule, 
  Configuration as IDLTAPIConfiguration, 
  ConfigurationParameters as IDLTAPIConfigurationParameters
} from '@root/api/idlt';
import { 
  ApiModule as ReportAPIModule, 
  Configuration as ReportAPIConfiguration, 
  ConfigurationParameters as ReportAPIConfigurationParameters
} from '@root/api/report';



import { routes } from '@/app.routes';

import { AppTitleStrategy } from '@/app-title.strategy';

import { fundsFeatureKey, fundsReducer } from '@funds/state/funds.reducer';
import { FundsEffects } from '@funds/state/funds.effects';
import { LoadingInterceptor } from './reports/loading.interceptor';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { DummyMsalService } from './shared-services/dummy.msal.service';

function boxAPIConfigFactory(): BoxAPIConfiguration {
  const params: BoxAPIConfigurationParameters = {
    basePath: environment.box.apiManagementBaseUrl
  }
  return new BoxAPIConfiguration(params);
}

function canoeAPIConfigFactory(): CanoeAPIConfiguration {
  const params: CanoeAPIConfigurationParameters = {
    basePath: environment.canoe.apiManagementBaseUrl
  }
  return new CanoeAPIConfiguration(params);
}

function entityAPIConfigFactory(): EntityAPIConfiguration {
  const params: EntityAPIConfigurationParameters = {
    basePath: environment.entity.apiManagementBaseUrl
  }
  return new EntityAPIConfiguration(params);
}

function idltAPIConfigFactory(): IDLTAPIConfiguration {
  const params: IDLTAPIConfigurationParameters = {
    basePath: environment.idlt.apiManagementBaseUrl
  }
  return new IDLTAPIConfiguration(params);
}

function reportAPIConfigFactory(): ReportAPIConfiguration {
  const params: ReportAPIConfigurationParameters = {
    basePath: environment.report.apiManagementBaseUrl
  }
  return new ReportAPIConfiguration(params);
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(
    environment.apiConfig.uri,
    environment.apiConfig.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes],
    },
    loginFailedRoute: '@core/components/page-not-found/page-not-found.component',
  };
}

export const msalProvider = {
  provide: MsalService,
  useClass: environment.authentication ? MsalService : DummyMsalService
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    ...(environment.authentication?
      [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
      ] : [msalProvider]
    ),

    importProvidersFrom([
      BoxAPIModule.forRoot(boxAPIConfigFactory),
      CanoeAPIModule.forRoot(canoeAPIConfigFactory),
      EntityAPIModule.forRoot(entityAPIConfigFactory),
      IDLTAPIModule.forRoot(idltAPIConfigFactory), 
      ReportAPIModule.forRoot(reportAPIConfigFactory),
      MatNativeDateModule
    ]),
    provideRouter(routes),
    provideAnimationsAsync(),
    {
        provide: TitleStrategy,
        useClass: AppTitleStrategy
    },
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: LoadingInterceptor, multi: true
    },
    provideStore(),
    provideState({ name: fundsFeatureKey, reducer: fundsReducer }),
    provideEffects(FundsEffects)
]
};
