import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RouterOutlet } from '@angular/router';
import { DEFAULT_QUERY_PAGE_SIZE } from './idlt/core/config';
import { TopnavComponent } from '@core/components/topnav/topnav.component';
import { getFunds } from '@funds/state/funds.actions';
import { ApiVersionService } from '@core/services/api-version.service';
import { SpinnerComponent } from './reports/spinner/spinner.component';
import { GeniiSharedServiceService } from './shared-services/genii-shared-service.service';
import { environment } from '@root/environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TopnavComponent,
    SpinnerComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{

  userGroups: string[] = [];

  constructor(
    private readonly store: Store<any>,
    private readonly apiVersionService: ApiVersionService,
    private geniiSharedService: GeniiSharedServiceService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.geniiSharedService.setAuthorizedGroups();
    if(environment.authentication) {
      // Initialize MSAL
      await this.geniiSharedService.initializeMsal();
    } else {
      this.geniiSharedService.fillGroups();
    }

    this.store.dispatch(getFunds({ pageSize: DEFAULT_QUERY_PAGE_SIZE, page: 0 }));
  }

}
