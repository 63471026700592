import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { APP_DEFAULT_ICON, APP_DEFAULT_TITLE } from "@core/config";
import { MatSelectModule } from '@angular/material/select';
import { Sponsor } from '@root/app/reports/interfaces';
import { FormsModule } from '@angular/forms';
import { GeniiSharedServiceService } from '@root/app/shared-services/genii-shared-service.service';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AppPaths } from '@root/app/app.routes';

@Component({
  selector: 'app-topnav',
  standalone: true,
  imports: [
    RouterLink,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatToolbarModule,
    MatFormFieldModule,
  ],
  templateUrl: './topnav.component.html',
  styleUrl: './topnav.component.scss'
})
export class TopnavComponent implements OnInit {
  title = APP_DEFAULT_TITLE;
  menu_icon: string = APP_DEFAULT_ICON;
  showSponsorSelect: boolean = false;
  breadCrumbs: string[] = [];

  links: {label: string; url: string; icon: string, action: string, showSponsorSelect: boolean}[] = [
    { label: '', url: '', icon: 'dataset', action: '', showSponsorSelect: false},
    { label: 'Fund Management', url: 'fund-management', icon: 'dataset', action: 'fund', showSponsorSelect: false},
    { label: 'Subscription Management', url: 'subscription-management', icon: 'card_membership', action: 'subscription', showSponsorSelect: false},
    { label: 'Capital Event Management', url: 'capital-event-management', icon: 'fact_check', action: 'capital_event', showSponsorSelect: false},
    { label: 'Canoe Data Extraction', url: 'canoe-data-extraction', icon: 'summarize', action: 'canoe', showSponsorSelect: true},
    { label: 'Document Data Extraction', url: 'ai-report', icon: 'summarize', action: 'ai-report', showSponsorSelect: true},
    { label: 'Reports', url: 'report', icon: 'ballot', action: 'report', showSponsorSelect: true},
    { label: 'Document Type', url: 'admin/document-type', icon: 'topic', action: 'document-type', showSponsorSelect: true},
  ];

  sponsors: Sponsor[] = [
    {sponsorID: '450', sponsorName: 'Demo Training', thumbnail: '', dataOrigin: 'intacct', tmpSessionId: '', accessToken: ''},
    {sponsorID: '153', sponsorName: 'Trive Funds Sandbox', thumbnail: '', dataOrigin: 'intacct', tmpSessionId: '', accessToken: ''},
    //{sponsorID: '471', sponsorName: 'Brighton Park', thumbnail: '', dataOrigin: 'dataBase', tmpSessionId: '', accessToken: ''}
    //{sponsorID: '5', sponsorName: 'Vista', thumbnail: '', dataOrigin: 'qlik', tmpSessionId: '', accessToken: ''}
  ];

  sponsor: Sponsor = this.sponsors[0];

  appPaths = AppPaths;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private geniiSharedService: GeniiSharedServiceService
  ) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
    .subscribe(() => {
      const rt = this.activatedRoute.firstChild || this.activatedRoute
      rt.data.subscribe(data => {
        this.title = data['title'] || APP_DEFAULT_TITLE;
        this.breadCrumbs = data['breadCrumbs'] || [];
        this.selectAction(data['action']);
      });
    });
  }
  
  selectAction(action: string): void {
    const selectedAction = this.links.filter(link => link.action === action)[0];
    if(selectedAction) {
      this.menu_icon = selectedAction.icon;
      this.showSponsorSelect = selectedAction.showSponsorSelect;
    }
  }

  onSponsorChange(sponsor: { value: Sponsor; }) {
    this.geniiSharedService.emitEvent(this.sponsors.find(s => s === sponsor.value));
  }

  canActivateItem(path: string) {
    return this.geniiSharedService.canActivateItem(path);
  }
}
