import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap } from 'rxjs';

import { FundsApiService } from '@funds/services/funds-api.service';
import * as actions from './funds.actions';


@Injectable()
export class FundsEffects {
  constructor (
    private readonly actions$: Actions,
    private readonly fundsApiService: FundsApiService
  ) {}

  getFunds$ = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_FUNDS),
    exhaustMap((action: any) => this.fundsApiService.getFunds(action.pageSize, action.page)
      .pipe(
        map((res: any) => {
          if (!res || !res.funds) {
            return actions.getFundsError({ error: 'Error getting funds.' })
          }
          return actions.getFundsSuccess({ 
            funds: res.funds, 
            fundsById: res.fundsById,
            getFundsComplete: res.pagesRemaining === 0
          });
        })
      ))
  ));
}