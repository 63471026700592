import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { ActionDetailService } from '../shared-services/action-detail.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(
    private loadingService: LoaderService,
    private actionDetailService: ActionDetailService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const actionDetail = this.actionDetailService.getActionDetail();
    this.totalRequests++;
    this.loadingService.setLoading(true);
    this.loadingService.setAction(actionDetail);
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.loadingService.setLoading(false);
          this.loadingService.setAction('');
        }
      })
    );
  }
}