import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { APP_NAME, APP_DEFAULT_TITLE } from "@core/config";

@Injectable()
export class AppTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const routerTitle = this.buildTitle(routerState) || APP_DEFAULT_TITLE;
    const customTitle = `${APP_NAME} | ${routerTitle}`;
    this.title.setTitle(customTitle);
  }
}